<template>
    <section class="blockElement innerMenu py-4 settings">
        <div class="container-fluid">
            <!-- Simulator Leader Profile Results Start Here -->
            <h1 class="medium f-28 darkblueColor mt-3 mb-1 line-1">{{$t('simulation.text1')}}</h1>
            <p class="mb-4">{{$t('simulation.text28')}}</p>
            <div class="row">
                <div class="col-12 col-lg-6 col-xl-8">
                    <div class="bordergS bg-white br-20 px-3 py-4">
                        <div class="row">
                            <div class="col-12 col-xl-5">
                                <div class="bodyBG br-16 p-4">
                                    <div v-if="store.user.access_token">
                                        <h6 class="mb-0 darkblueColor f-14 medium">{{$t('simulation.text2')}}</h6>

                                        <div id="advancedSearch" class="d-flex align-items-center justify-content-between bigDropdown" v-if="store.userTradingAccountsList.length">
                                            <div class="position-relative advancedMenu currencypair w-100 mt-1">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="selectDropdown bg-white d-flex align-items-center rounded-pill position-relative showClass"
                                                    @click="showAccount = !showAccount"
                                                    v-if="Object.keys(store.userSelectedAccount).length"
                                                >
                                                    <i class="me-2"><img v-if="store.userSelectedAccount.brokerLogo" :src="'https://zulutrade.com/'+store.userSelectedAccount.brokerLogo" /></i>
                                                    <span class="f-15">{{store.userSelectedAccount.username}}</span>
                                                    <vue-feather type="chevron-down" class="w-18 me-2 bigDropdown-arrow position-absolute"></vue-feather>
                                                </a>
                                                <ul class="dropdown_menu_animated week pb-0" :class="[{'show' : showAccount}]">
                                                    <!-- Show Class -->
                                                    <li :class="[{'disabled':list.connectivityStatus !== 'Connected'}]" v-for="list,index in accountList()" :key="index">
                                                        <a class="d-flex align-items-center" href="javascript:void(0)" @click="changeAccount(list)">
                                                            <i class="me-2"><v-lazy-image v-if="list.brokerLogo" :src="'https://zulutrade.com/'+list.brokerLogo" /></i>
                                                            <span class="f-15">{{list.username}}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else>{{$t('simulation.text3')}}</div>
                                        <div class="d-flex align-items-center mt-1">
                                            <vue-feather type="info" class="w-14 gray me-1"></vue-feather>
                                            <span class="f-10 medium gray">{{$t('simulation.text4')}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mt-3 position-relative dollerSign">
                                        <input type="number" class="form-control midgray lightblueBG bigInput rounded-pill bg-transparent f-15 gray w-100" v-model="amount"  />
                                        <span class="position-absolute leftZ" v-if="store.user.access_token">{{store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$'}}</span>
                                        <span class="position-absolute leftZ" v-else>$</span>
                                        <!-- @input="changeAmount($event)" -->
                                    </div>
                                    <div class="mt-4">
                                        <h6 class="mb-2 darkblueColor f-14 medium">{{$t('simulation.text6')}}</h6>
                                        <div class="position-relative customRangeSlider d-block w-100 mt-1">
                                            <!-- <Slider v-model="slider.value" :step="step" :min="slider.low" :max="slider.high" /> -->
                                            <input type='range' v-model="slider.value" :step="step" :min="slider.low" :max="slider.high" class="n n4"/>
                                            <ul class="range-labels position-relative d-flex justify-content-between mb-0">
                                                <li :class="[{'selected':slider.value=='12'}, {'selected':slider.value=='18'}, {'selected':slider.value=='24'}]" class="f-15 gray">6m</li> <!--Add class 'selected'-->
                                                <li :class="[{'selected':slider.value=='18'}, {'selected':slider.value=='24'}]" class="f-15 gray">12m</li>
                                                <li :class="[{'selected':slider.value=='24'}]" class="f-15 gray">18m</li>
                                                <li class="f-15 gray">24m</li>
                                            </ul>
                                        </div>
                                    </div>
                                   <!--  <a href="javacript:void(0);" class="d-flex align-items-center mt-3">
                                        <h6 class="mb-0 darkblueColor f-16 medium me-1">Advanced stats</h6>
                                        <vue-feather type="chevron-down" class="w-18 gray"></vue-feather>
                                    </a> -->

                                    <a href="javascript:void(0)" class="button fillBtn py-2 zulu_btn x-large me-3 px-md-4 mt-4 w-100" :class="[{'disabled' : !amount || !validPercent() || parseInt(amount) > 10000000 || store.customerDetail?.readOnly}]" @click="startSimulation()">{{$t('simulation.text5')}}</a>
                                </div>
                            </div>
                            <div class="col-12 col-xl-7">
                                <h5 class="mb-0 darkblueColor f-22 medium mt-3 mt-xl-0">{{$t('simulation.text7')}}</h5>
                                <div v-if="Object.keys(store.compareTraderPerformance).length && store.compareTraderPerformance.result && store.compareTraderPerformance.result.length">
                                    <div class="d-sm-flex d-block align-items-end mt-4 justify-content-between strategies-col position-relative" v-for="list,index in store.compareTraderPerformance.result" :key="index">
                                        <router-link :to="`/trader/${list.trader.providerId}/trading?t=${Object.keys(list.trader.timeframeStats)[0]}&m=1`" class="d-flex align-items-center" v-if="list.trader && list.trader.profile">
                                            <i class="d-flex"><v-lazy-image width="32" height="32" class="rounded-circle" :src="(!list.trader.profile.photoApproved) ? (list.trader.profile.customerAvatarUrl) ? list.trader.profile.customerAvatarUrl : static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+list.trader.profile.zuluAccountId+'&ignore=false' : static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+list.trader.profile.zuluAccountId+'&ignore=false'" /></i>
                                            <h6 :title="list.trader.profile.name" class="ellipsText f-15 medium darkblueColor mb-0 ms-2" v-if="list.trader.profile.name">
                                                {{list.trader.profile.name}}
                                                <!-- <p class="f-12 regular gray mb-0 mt-1">
                                                    StrategyUnnamed
                                                </p> -->
                                            </h6>
                                        </router-link>

                                        <div class="d-flex align-items-center text-center strategies-col2">
                                            <div class="d-inline-block" v-if="list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                <h6 class="mb-0 bg-white f-14 semibold border-0" :class="(parseInt(list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0) ? 'greenView' : 'redView'">
                                                    {{ parseInt(list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : '' }}{{
                                                    parseFloat(list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2) || 0 }}%
                                                </h6>
                                                <span class="f-12 regular gray medium text-uppercase">{{$t('simulation.text8')}}</span>
                                            </div>
                                            <div class="d-inline-block mx-2 mx-xl-2 mx-xxl-4" v-if="list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].hasOwnProperty('winTrades')">
                                                <h6 class="mb-0 darkblueColor f-14 semibold">{{list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].winTrades}}%</h6>
                                                <span class="f-12 regular gray medium text-uppercase">{{$t('simulation.text9')}}</span>
                                            </div>
                                            <div class="d-inline-block" v-if="list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">
                                                <h6 class="mb-0 bg-white f-14 semibold" :class="(list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk) ? roiJSon[list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk] : ''">{{list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk}}</h6>
                                                <span class="f-12 regular gray medium text-uppercase">{{$t('simulation.text10')}}</span>
                                            </div>
                                        </div>
                                        <div class="d-sm-flex d-inline-block align-items-end strategies-col3">
                                            <div class="d-sm-block d-inline-block position-relative">
                                                <p class="smallPrice mb-0">${{(amount / 100) * parseFloat(percent[list.trader.providerId]) || 0}}</p>
                                                <p class="f-12 regular gray mb-0">
                                                    {{$t('simulation.text11')}}
                                                </p>
                                                <div class="mt-1 percent_input position-relative">
                                                    <input type="number" class="form-control gray px-2 f-14 py-1" v-model="percent[list.trader.providerId]" />
                                                   <!--  @input="changePercent($event,list.trader.providerId)" -->
                                                    <span class="position-absolute rounded-2 moregray">%</span>
                                                </div>
                                            </div>
                                            <div class="d-sm-block d-inline-block ms-xl-2 ms-xxl-4 ms-2" v-if="tradersId.length >=1">
                                                <a href="javascript:void(0);" class="d-block" @click="removeTrader(list)">
                                                    <v-lazy-image src="/assets/images/simulator/gray-cross.svg" class="redMode br-50 d-block mb-sm-2 mb-0" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p> {{$t('simulation.text12')}}</p>
                                </div>

                                <a href="javascript:void(0)" class="button zulu_btn compare_btn rounded border-button ps-1 dark-text mt-4 medium" @click="addTraders = !addTraders;getTradersList();serachTrader = ''"  :class="{'disabled' : store.customerDetail?.readOnly}">
                                    <v-lazy-image src="/assets/images/orange-plus.svg" :alt="$t('simulation.text27')" :title="$t('simulation.text27')" class="me-2" />{{$t('simulation.text13')}}
                                </a>
                                <a href="javacript:void(0);" class="d-flex align-items-center mt-5" v-if="Object.keys(store.compareTraderPerformance).length && store.compareTraderPerformance.result && store.compareTraderPerformance.result.length && store.user.access_token && Object.keys(store.copyTraders).length && store.copyTraders.providers && store.copyTraders.providers.length">
                                    <h6 class="mb-0 darkblueColor f-16 medium me-1">{{$t('simulation.text14')}}</h6>
                                    <vue-feather type="chevron-up" class="w-18 me-2"></vue-feather>
                                </a>

                                <div class="row" v-if="Object.keys(store.compareTraderPerformance).length && store.compareTraderPerformance.result && store.compareTraderPerformance.result.length && store.user.access_token && Object.keys(store.copyTraders).length && store.copyTraders.providers && store.copyTraders.providers.length">
                                    <div class="col-12 col-xl-12 col-xxl-6" v-for="item,index in getCopyLeadersList()" :key="index">
                                            <div class="bordergS bg-white br-16 p-3 mt-3 d-flex align-items-center justify-content-between leaderToCompare">
                                                <router-link :to="`/trader/${item.providerId}/trading?t=10000&m=1`" class="d-flex align-items-center">
                                                    <i class="d-inline-block"><v-lazy-image class="rounded-circle d-block" :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.providerId + '&ignore=false'" width="48" height="48" /></i>
                                                    <div class="d-block ms-3">
                                                        <h6 class="f-16 medium darkblueColor mb-0 d-inline-block">
                                                            {{item.providerName}}
                                                            <!-- <p class="f-12 regular gray mb-0 mt-1">
                                                                StrategyUnnamed
                                                            </p> -->
                                                        </h6>
                                                       <!--  <div class="d-flex align-items-center w-100">
                                                            <span class="f-12 regular gray medium text-uppercase me-1">ROI</span>
                                                            <h6 class="mb-0 f-13" :class="(parseFloat(item.roi) > 0.0) ? 'dashgreenColor' : 'redmanacolor'">{{parseFloat(item.roi) > 0.0 ? '+' : ''}}{{item.roi}}%</h6>
                                                        </div> -->
                                                    </div>
                                               </router-link>
                                                <div class="addComButton">
                                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-if="!tradersId.includes(item.providerId.toString())"  :class="{'disabled' : store.customerDetail?.readOnly}"><vue-feather type="plus-circle"></vue-feather> </a>
                                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-else><vue-feather type="minus"  :class="{'disabled' : store.customerDetail?.readOnly}"></vue-feather> </a>
                                                </div>
                                            </div>
                                            <!-- <div class="d-flex align-items-center text-center mt-2">
                                                
                                                <div class="d-inline-block mx-4">
                                                    <h6 class="mb-0 darkblueColor f-14 semibold">80%</h6>
                                                    <span class="f-12 regular gray medium text-uppercase">WIN</span>
                                                </div>
                                                <div class="d-inline-block">
                                                    <h6 class="mb-0 f-14 semibold">5</h6>
                                                    <span class="f-12 regular gray medium text-uppercase">RISK</span>
                                                </div>
                                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-4">
                    <div class="position-relative">
                        <div class="singleLoading bordergS bg-white br-20 mt-4 mt-lg-0" v-if="store.singleLoading">
                            <Loader :classname="'innerLoader'"></Loader>
                        </div>
                        <div class="bordergS bg-white br-20 px-3 py-4 mt-4 mt-lg-0" v-show="!store.singleLoading && chartData.length && simulationStatus == 'SIMULATION_ENDED' && showResult && store.compareTraderPerformance?.result?.length">
                            <h5 class="mb-0 darkblueColor f-22 medium">{{$t('simulation.text15')}}</h5>
                            <div class="position-relative mt-3">
                                <div class="mb-3">
                                    <ul id="tabs-nav" class="zuluTabs zulubtnTabs singleSmall d-flex align-items-center mb-0">
                                        <li class="rounded-pill" @click="filterType = 'All'" :class="[{'active' : filterType == 'All'}]"><a class="f-14 medium darkblueColor" href="javascript:void(0);">{{$t('simulation.text16')}}</a></li>
                                        <li class="rounded-pill" v-for="list,index in store.compareTraderPerformance.result" :key="index" @click="filterType = list.trader.providerId" :class="[{'active' : filterType == list.trader.providerId}]"><a class="f-14 medium rounded-pill" href="javascript:void(0);">{{list.trader.profile.name}}</a></li>
                                    </ul>
                                </div>
                                <div class="tab-content" v-show="filterType == 'All'">
                                <div :id="'LeaderSimulationChartAll'" style="width:100%;minHeight:400px" :style="{height: `${getHeight()}px`}" class="LeaderSimulationChartAll"></div>
                                </div>
                                <div class="tab-content" v-show="filterType != 'All'">
                                    <div :id="'LeaderSimulationChart'+list.trader.providerId" style="width: 100%; height: 400px;" v-for="list,index in store.compareTraderPerformance.result" :key="index" v-show="filterType == list.trader.providerId"></div>
                                </div>
                            </div>

                            <h6 class="mb-0 darkblueColor f-18 medium me-1 mt-2">{{$t('simulation.text17')}} <span class="gray">{{slider.value}}M</span></h6>

                            <div class="row mt-3">
                                <div class="col-4 text-center">
                                    <h6 class="mb-0 f-18 medium dashgreenColor" v-if="filterType != 'All'">{{(store.user.access_token) ? store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$' : '$'}}{{finalData[filterType].initialBalance || 0}}</h6>
                                    <h6 class="mb-0 f-18 medium dashgreenColor" v-else>{{(store.user.access_token) ? store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$' : '$'}}{{amount || 0}}</h6>
                                    <span class="f-12 regular gray medium text-uppercase">{{$t('simulation.text18')}}</span>
                                </div>
                                <div class="col-4 text-center">
                                    <h6 class="mb-0 darkblueColor f-18 medium" v-if="filterType != 'All'">{{parseFloat(finalData[filterType].endingBalance) >= 0 ? '' : '-'}}{{(store.user.access_token) ? store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$' : '$'}}{{parseFloat(Math.abs(finalData[filterType].endingBalance)).toFixed(2) || 0}}</h6>
                                    <h6 class="mb-0 darkblueColor f-18 medium" v-if="chartData.length && filterType == 'All'">{{parseFloat(chartData[chartData.length - 1].eodBalance) >= 0 ? '' : '-'}}{{(store.user.access_token) ? store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$' : '$'}}{{Math.abs(parseFloat(chartData[chartData.length - 1].eodBalance)).toFixed(2) || 0}}</h6>
                                    <span class="f-12 regular gray medium text-uppercase">{{$t('simulation.text19')}}</span>
                                </div>
                                <div class="col-4 text-center">
                                    <h6 class="mb-0 darkblueColor f-18 medium" v-if="filterType != 'All'">{{parseFloat(finalData[filterType].totalProfit) >= 0 ? '' : '-'}}{{(store.user.access_token) ? store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$' : '$'}}{{parseFloat(Math.abs(finalData[filterType].totalProfit)).toFixed(2) || 0}}</h6>
                                    <h6 class="mb-0 darkblueColor f-18 medium" v-if="chartData.length && filterType == 'All'">{{parseFloat(chartData[chartData.length - 1].eodBalance-amount) >= 0 ? '' : '-'}}{{(store.user.access_token) ? store.allCurrencySymbols[store.userSelectedAccount?.baseCurrencyId]?.htmlSymbol || '$' : '$'}}{{parseFloat(Math.abs(chartData[chartData.length - 1].eodBalance-amount)).toFixed(2) || 0}}</h6>
                                    <span class="f-12 regular gray medium text-uppercase">{{$t('simulation.text20')}}</span>
                                </div>
                            </div>
                            <!-- <div class="row mt-4">
                                <div class="col-4 text-center">
                                    <h6 class="mb-0 f-18 medium darkblueColor">32718</h6>
                                    <span class="f-12 regular gray medium text-uppercase">TRADES RECEIVED</span>
                                </div>
                                <div class="col-4 text-center">
                                    <h6 class="mb-0 darkblueColor f-18 medium">1679</h6>
                                    <span class="f-12 regular gray medium text-uppercase">TRADES OPENED</span>
                                </div>
                                <div class="col-4 text-center">
                                    <h6 class="mb-0 darkblueColor f-18 medium">31141</h6>
                                    <span class="f-12 regular gray medium text-uppercase">TRADES CLOSED</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!--  <div v-else>
                <p>Please come from leader detail page.</p>
            </div> -->
            <!-- Simulator Leader Profile Results End Here -->
        </div>
    </section>
    <div class="modal" v-if="addTraders" :class="[{'d-block' : addTraders && store.topTrdaersList.length}]">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <h5 class="modal-title">{{$t('simulation.text13')}}</h5>
                    <button type="button" class="btn-close" @click="addTraders = false"></button>
                </div>
                <div class="modal-body leaderToCompare">
                    <div class="col-12 mb-4">
                        <div class="form-search zuluInput w-100">
                            <input type="search" class="form-control" placeholder="Search Leader" v-model="serachTrader" @keyup="page=1;callleaderSearchAPI($event)" />
                            <a href="javascript:void(0)" class="searchIcon">
                                <vue-feather class="searchIcon searchIcon-auto" type="search"></vue-feather>
                            </a>
                        </div>
                    </div>
                    <p class="medium" v-if="getTopPerformingLeaders.list.length">{{$t('simulation.text21')}}</p>
                    <div class="d-flex justify-content-between align-items-center flex-wrap" v-if="!serachTrader">
                        <div class="trader-list" v-for="item,index in getTopPerformingLeaders.list" :key="index">
                            <div class="d-flex align-items-center justify-content-between">
                                <!-- <div class="me-2">
                                    <label class="cutomChechBox">
                                        <input type="checkbox" v-model="tradersId" :name="'checkbox'+item.providerId" :value="item.providerId" @change="$router.push({query:{'p':tradersId.join(',').toString()}});getTraderPerformanceData()" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div> -->
                                <div class="d-flex align-items-center">
                                    <span class="zlds-avatar-small menuitem position-relative d-block me-2 flex-none">
                                        <v-lazy-image class="rounded-circle" :src="static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.trader.providerId+'&ignore=false'" :alt="item.name" :title="item.name" />
                                    </span>
                                    <div class="medium f-18 secondary"><span class="">{{item.trader.profile.name}}</span></div>
                                </div>
                                <div class="addComButton">
                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-if="!tradersId.includes(item.trader.providerId.toString())"><vue-feather type="plus-circle"></vue-feather> </a>
                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-else><vue-feather type="minus"></vue-feather> </a>
                                </div>
                            </div>

                            <div class="d-flex flex-column w-100 ps-2">
                                <div class="d-flex justify-content-between overflow-auto">
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text22')}}</div>
                                        <span class="f-14 medium">
                                            ${{item.trader.overallStats.amountFollowing ? Math.abs(item.trader.overallStats.amountFollowing) > 999 ? Math.sign(item.trader.overallStats.amountFollowing)*((Math.abs(item.trader.overallStats.amountFollowing)/1000).toFixed(1)) + 'k' :
                                            Math.sign(item.trader.overallStats.amountFollowing)*Math.abs(item.trader.overallStats.amountFollowing) : 0}}
                                        </span>
                                    </div>
                                    <div class="listBlock px-3">
                                        <div class="small f-12 inactive">{{$t('simulation.text23')}}</div>
                                        <span class="f-14 medium">{{item.trader.overallStats.weeks || 0}}</span>
                                    </div>
                                    <div class="listBlock px-3">
                                        <div class="small f-12 inactive">{{$t('simulation.text24')}}</div>
                                        <span class="f-14 medium">{{item.trader.overallStats.includedInWatchlist}}</span>
                                    </div>
                                    <div class="listBlock px-3">
                                        <div class="small f-12 inactive">{{$t('simulation.text25')}}</div>
                                        <span class="f-14 medium">{{item?.trader?.timeframeStats[Object.keys(item?.trader?.timeframeStats)?.[0]]?.annualizedRorBasedRoi ? parseFloat(item?.trader?.timeframeStats[Object.keys(item?.trader?.timeframeStats)?.[0]]?.annualizedRorBasedRoi.toFixed(2)).toLocaleString() : 0}}%</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text26')}}</div>
                                        <span class="f-14 medium">#{{item.trader.overallStats.zuluRank}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="d-flex justify-content-between align-items-center flex-wrap" v-else>
                        <div class="trader-list" v-for="item,index in getTopPerformingLeaders.list" :key="index">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <span class="zlds-avatar-small menuitem position-relative d-block me-2 flex-none">
                                        <v-lazy-image class="rounded-circle" :src="static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false'" :alt="item.name" :title="item.name" />
                                    </span>
                                    <div class="medium f-18 secondary"><span class="">{{item.name}}</span></div>
                                </div>
                                <div class="addComButton">
                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-if="!tradersId.includes(item.providerId.toString())"><vue-feather type="plus-circle"></vue-feather> </a>
                                    <a href="javascript:void(0)" @click="AddToCompare(item)" v-else><vue-feather type="minus"></vue-feather> </a>
                                </div>
                            </div>

                            <div class="d-flex flex-column w-100 ps-2">
                               
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text22')}}</div>
                                        <span class="f-14 medium">${{Math.abs(item.amountFollowing) > 999 ? Math.sign(item.amountFollowing)*((Math.abs(item.amountFollowing)/1000).toFixed(1)) + 'k' : Math.sign(item.amountFollowing)*Math.abs(item.amountFollowing)}}</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text23')}}</div>
                                        <span class="f-14 medium">{{item.weeks}}</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text24')}}</div>
                                        <span class="f-14 medium">{{item.followers}}</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text25')}}</div>
                                        <span class="f-14 medium">{{parseFloat(item.rorBasedRoi.toFixed(2)).toLocaleString()}}%</span>
                                    </div>
                                    <div class="listBlock">
                                        <div class="small f-12 inactive">{{$t('simulation.text26')}}</div>
                                        <span class="f-14 medium">#{{item.zuluRank}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NoData v-if="!getTopPerformingLeaders.list.length"/>
                    </div>
                </div>
                <div class="modal-footer border-0 pt-0" v-if="!serachTrader">
                    <vue-feather class="icon" type="chevron-left" v-if="Math.ceil(getTopPerformingLeaders.total / limit) > 1 && page > 1" @click="page=parseInt(page)-1;"></vue-feather>
                    <vue-feather class="icon" type="chevron-right" @click="page=parseInt(page)+1;" v-if="Math.ceil(getTopPerformingLeaders.total / limit) > page"></vue-feather>
                </div>
                <div class="modal-footer border-0 pt-0" v-else>
                    <vue-feather class="icon" type="chevron-left" v-if="Math.ceil(getTopPerformingLeaders.total / limit) > 1 && page > 1" @click="page=parseInt(page)-1;callleaderSearchAPI()"></vue-feather>
                    <vue-feather class="icon" type="chevron-right" @click="page=parseInt(page)+1;callleaderSearchAPI()" v-if="Math.ceil(getTopPerformingLeaders.total / limit) > page"></vue-feather>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        myStore
    } from "@/store/pinia-store";
    import moment from "moment";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import $ from "jquery";
    import _ from 'lodash'
    import NoData from "@/components/shared/no-data.vue";
    export default {
        setup() {
            const store = myStore();
            return {
                store
            };
        },
        data() {
            return {
                showAccount: false,
                tradersId: [],
                addTraders: false,
                page: 1,
                limit: 4,
                serachTrader: "",
                loader: false,
                amount: 10000,
                slider: {
                    value: 6,
                    low: 6,
                    high: 24
                },
                step: 6,
                percent: {},
                socket: "",
                chartData: [],
                simulationStatus: "PENDING",
                tabtemp: [],
                finalData: {},
                filterType: 'All',
                roiJSon: {
                    1: 'blueBtn',
                    2: 'greenBtn',
                    3: 'yellowBtn',
                    4: 'redBtn',
                    5: 'pinkBtn'
                },
                showResult: false
            };
        },
        components: { NoData },
        computed:{
            getTopPerformingLeaders(){
                if(this.store.topTrdaersList.length){
                    let returnList = [];
                    if (this.store.topTrdaersList?.[0] && this.store.topTrdaersList[0].result && this.store.topTrdaersList[0].result.length && parseInt(this.store.topTrdaersList[0].filterId) == 75932) {
                        if (this.serachTrader) {
                            if (this.store.searchTradersList.length) {
                                returnList = this.store.searchTradersList;
                            } else {
                                returnList = []
                            }
                        } else {
                            returnList = this.store.topTrdaersList[0].result.filter(
                                (val, index) =>
                                index < this.page * this.limit &&
                                index >= (this.page - 1) * this.limit
                            );
                        }
                        if (this.serachTrader) {
                            return {
                                list: returnList,
                                total: this.store.searchTradersList.length
                            }
                        } else {
                            return {
                                list: returnList,
                                total: this.store.topTrdaersList[0].result.length
                            }
                        }
                    } else {
                        if (this.store.topTrdaersList ?.[0] && this.store.topTrdaersList[0].result && this.store.topTrdaersList[0].result.length) {
                            if (this.serachTrader) {
                                if (this.store.searchTradersList.length) {
                                    returnList = this.store.searchTradersList;
                                } else {
                                    returnList = []
                                }
                            } else {
                                returnList = this.store.topTrdaersList[0].result.filter(
                                    (val, index) =>
                                    index < this.page * this.limit &&
                                    index >= (this.page - 1) * this.limit
                                );
                            }
                            if (this.serachTrader) {

                                return {
                                    list: returnList,
                                    total: this.store.searchTradersList.length
                                }
                            } else {

                                return {
                                    list: returnList,
                                    total: this.store.topTrdaersList[0].result.length
                                }
                            }
                        } else {
                            let data = [];
                            if (this.serachTrader) {
                                if (this.store.searchTradersList.length) {
                                    data = this.store.searchTradersList;
                                } else {
                                    data = []
                                }
                            } else {
                                this.store.topTrdaersList.forEach(function(top_tra) {
                                    if (top_tra.result.length > 0) {
                                        data = top_tra.result;
                                    }
                                });
                            }
                            returnList = data;
                            let total = data.length;
                            return {
                                'list': returnList,
                                'total': total
                            }
                        }
                    }
                } else {
                    return {
                        list: [],
                        total: 0
                    }
                }
            }
        },
        methods: {
            callleaderSearchAPI: _.debounce(function() {
                this.store.callFollowersCompareList({}, false, parseInt(this.page) - 1, this.serachTrader);
            }, 500),
            accountList() {
                if (this.store.userTradingAccountsList.length && Object.keys(this.store.userSelectedAccount).length) {
                    return this.store.userTradingAccountsList.filter(i => i.zuluAccountId != this.store.userSelectedAccount.zuluAccountId)
                } else {
                    return []
                }
            },
            validPercent() {
                if (Object.keys(this.percent).length) {
                    let sum = 0;
                    for (var key in this.percent) {
                        sum += parseInt(this.percent[key]);
                    }
                    if (sum > 0 && sum > 100) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            },
            getCopyLeadersList() {
                if (Object.keys(this.store.copyTraders).length && this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                    let data = [];
                    if (this.store.allTypeAccount) {
                        if (this.store.allTypeAccount == "ALL_LIVE") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === false);
                        } else if (this.store.allTypeAccount == "ALL_DEMO") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === true);
                        }
                    } else {
                        if (Object.keys(this.store.userSelectedAccount).length) {
                            data = this.store.copyTraders.providers.filter((i) => i.zuluAccountId == this.store.userSelectedAccount.zuluAccountId);
                        } else {
                            data = [];
                        }
                    }
                    data = data.filter(i => !this.tradersId.includes(i.providerId.toString()));
                    return data
                } else {
                    return []
                }
            },
            changeAccount(list){
                if(list.connectivityStatus === 'Connected'){
                    this.showAccount = false
                    this.store.$patch({'userSelectedAccount' : list})
                    this.store.getUserDashboardData({},true,'','10000')
                    let form = {
                    'targetBrokerAccountId' : list.brokerAccountId,
                    'page' : 0,
                    'size' : 10,
                    'sort' : 'id,desc',
                    };
                    this.store.getProviderOpenTrades({},true);
                    this.store.userNotifications(form,false)
                }
            },
            startSimulation() {
                this.showResult = true
                let form = {
                    dateFrom: moment().subtract(this.slider.value, "months").unix(),
                    dateTo: moment().subtract(1, "day").unix(),
                    providers: [],
                };
                if (this.chartData.length) {
                    this.tabtemp = []
                }
                if (this.store.user.access_token && Object.keys(this.store.userSelectedAccount).length) {
                    form["targetBrokerAccountId"] = this.store.userSelectedAccount.brokerAccountId;
                }
                if (this.tradersId.length) {
                    this.tabtemp = []; /*[{ name: "All", providerId: "All", initialBalance: this.amount }]*/
                    if (Object.keys(this.store.compareTraderPerformance).length && this.store.compareTraderPerformance.result && this.store.compareTraderPerformance.result.length) {
                        this.store.compareTraderPerformance.result.map((leader) => {
                            let obj = {
                                providerId: leader.trader.providerId,
                                allocatedCapital: (this.amount / 100) * parseFloat(this.percent[leader.trader.providerId]),
                                prorataPercentage: this.percent[leader.trader.providerId],
                            };
                            this.tabtemp.push({
                                name: leader.trader.profile.name,
                                providerId: leader.trader.providerId,
                                initialBalance: this.amount
                            });
                            form.providers.push(obj);
                        });
                    }
                }
                if (this.chartData.length || Object.keys(this.finalData).length) {
                    this.chartData = []
                    this.finalData = {}
                    this.filterType = 'All'
                }
                this.socket.send(JSON.stringify(form));
            },
            changePercent(e, ID) {
                if (this.amount && e.target.value && Object.keys(this.percent).length) {
                    if (parseInt(e.target.value) < 100) {
                        for (let id in this.percent) {
                            if (parseInt(id) == parseInt(ID)) {
                                this.percent[id] = parseInt(e.target.value).toFixed(1);
                            } else {
                                let balance = parseInt(100 - parseInt(e.target.value));
                                if (balance > 0.0) {
                                    let value = parseInt(balance) / (Object.keys(this.percent).length - 1);
                                    //this.percent[id] = parseFloat((parseFloat(value) / parseFloat(this.amount)) * 100).toFixed(1);
                                    this.percent[id] = parseFloat(value).toFixed(1)
                                } else {
                                    this.percent[id] = 0;
                                }
                            }
                        }
                    } else {
                        if (parseInt(e.target.value) > 100) {
                            for (let id in this.percent) {
                                if (parseInt(id) == parseInt(ID)) {
                                    this.percent[id] = 100;
                                } else {
                                    this.percent[id] = 0;
                                }
                            }
                        }
                    }
                    //console.log('===>', this.percent)
                }
            },
            changeAmount(e) {
                if (e.target.value && Object.keys(this.percent).length) {
                    for (var key in this.percent) {
                        let value = parseFloat(e.target.value) / Object.keys(this.percent).length;
                        this.percent[key] = parseFloat((parseFloat(value) / parseFloat(e.target.value)) * 100).toFixed(1);
                    }
                } else {
                    for (var id in this.percent) {
                        this.percent[id] = 0;
                    }
                }
            },
            setDefaultPercent() {
                if (this.amount && Object.keys(this.percent).length) {
                    for (var key in this.percent) {
                        let value = parseFloat(this.amount) / Object.keys(this.percent).length;
                        this.percent[key] = parseFloat((parseFloat(value) / parseFloat(this.amount)) * 100).toFixed(1);
                    }
                } else {
                    for (var id in this.percent) {
                        this.percent[id] = 0;
                    }
                }
            },
            getTraderPerformanceData(type) {
                let formData = {};
                formData["flavor"] = "global";
                formData["includeProviderIds"] = this.tradersId;
                this.store.compareTraderPerformanceData(formData, false, this).then((response) => {
                    if (Object.keys(response).length && response.result && response.result.length) {
                        //this.percent = {}
                        if (type) {
                            if (type == 'Start') {
                                this.percent = {};
                                response.result.forEach((val) => {
                                    if (val.trader && val.trader.providerId) {
                                        this.percent[val.trader.providerId] = 0;
                                    }
                                });
                                this.setDefaultPercent()
                            } else {
                                this.percent = {};
                                response.result.forEach((val) => {
                                    if (val.trader && val.trader.providerId) {
                                        this.percent[val.trader.providerId] = 0;
                                    }
                                });
                                if (this.amount) {
                                    let value = parseFloat(this.amount) / Object.keys(this.percent).length;
                                    response.result.forEach((val) => {
                                        if (val.trader && val.trader.providerId) {
                                            this.percent[val.trader.providerId] = parseFloat((parseFloat(value) / parseFloat(this.amount)) * 100).toFixed(1);
                                        }
                                    });
                                } else {
                                    response.result.forEach((val) => {
                                        if (val.trader && val.trader.providerId) {
                                            this.percent[val.trader.providerId] = 0;
                                        }
                                    });
                                }
                            }
                        } else {
                            response.result.forEach((val) => {
                                if (val.trader && val.trader.providerId) {
                                    this.percent[val.trader.providerId] = 0;
                                }
                            });
                            this.setDefaultPercent()
                        }
                    }
                });
            },
            getTradersList() {
                if (!this.store.topTrdaersList.length) {
                    this.store.callTopTradersList({}, false);
                }
            },
            AddToCompare(item) {
                this.showResult = false
                if (item.trader && item.trader.providerId) {
                    let index = this.tradersId.indexOf(item.trader.providerId.toString());
                    if (index > -1) {
                        this.tradersId.splice(index, 1);
                        this.getTraderPerformanceData();
                        this.$router.push({
                            query: {
                                id: this.tradersId.join(",")
                            }
                        });
                    } else {
                        this.tradersId.push(item.trader.providerId.toString());
                        this.getTraderPerformanceData();
                        this.$router.push({
                            query: {
                                id: this.tradersId.join(",")
                            }
                        });
                    }
                } else {
                    let index = this.tradersId.indexOf(item.providerId.toString());
                    if (index > -1) {
                        this.tradersId.splice(index, 1);
                        this.getTraderPerformanceData();
                        this.$router.push({
                            query: {
                                id: this.tradersId.join(",")
                            }
                        });
                    } else {
                        this.tradersId.push(item.providerId.toString());
                        this.getTraderPerformanceData();
                        this.$router.push({
                            query: {
                                id: this.tradersId.join(",")
                            }
                        });
                    }
                }
            },
            removeTrader(item) {
                this.showResult = false
                if (item.trader && item.trader.providerId) {
                    let index = this.tradersId.indexOf(item.trader.providerId.toString());
                    if (index > -1) {
                        this.tradersId.splice(index, 1);
                        this.getTraderPerformanceData("REMOVE");
                        this.$router.push({
                            query: {
                                id: this.tradersId.join(",")
                            }
                        });
                    }
                } else {
                    let index = this.tradersId.indexOf(item.providerId.toString());
                    if (index > -1) {
                        this.tradersId.splice(index, 1);
                        this.getTraderPerformanceData("REMOVE");
                        this.$router.push({
                            query: {
                                id: this.tradersId.join(",")
                            }
                        });
                    }
                }
            },
            connectSimulationSocket() {
                const _that = this;
                _that.socket = new WebSocket("wss://www.zulutrade.com/zulutrade-gateway/statsservice/simulate");
                _that.socket.addEventListener('open', () => {
                    //console.log('open',e)
                })
                _that.chartData = [];
                _that.socket.addEventListener('message', (event) => {
                    let socketResponse = JSON.parse(event.data);
                    if (socketResponse.event && (socketResponse.event == "SIMULATION_STARTED" || socketResponse.event == "SIMULATION_SUBMITTED")) {
                        _that.store.$patch({
                            singleLoading: true
                        });
                    } else if (socketResponse.event && socketResponse.event == "EOD") {
                        _that.chartData.push(socketResponse);
                        _that.simulationStatus = "EOD";
                    } else if (socketResponse.event && socketResponse.event == "SIMULATION_ENDED") {
                        _that.store.$patch({
                            singleLoading: false
                        });
                        _that.combineData();
                        _that.simulationStatus = "SIMULATION_ENDED";
                    }
                })
                _that.socket.addEventListener('close', () => {
                        _that.socket = new WebSocket("wss://www.zulutrade.com/zulutrade-gateway/statsservice/simulate");
                    }) // if connection closed
                _that.socket.addEventListener('error', () => {})

            },
            drawSimulationChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d ?.dispose();
                // console.log('===>',am5.registry)
                var root = am5.Root.new(id);
                root.interfaceColors.set("grid", am5.color(0xffffff));
                root.setThemes([am5themes_Animated.new(root)]);
                root.setThemes([am5themes_Animated.new(root)]);
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                        //  layout: root.verticalLayout,
                        // panX: false,
                        // panY: false,
                        // wheelX: "zoomY",
                        // wheelY: "zoomX",
                        // pinchZoomX: true
                    })
                );

                //var easing = am5.ease.linear;
                chart.get("colors").set("step", 3);
                var xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        maxDeviation: 0.1,
                        groupData: false,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        tooltip: am5.Tooltip.new(root, {}),
                    })
                );
                var yRenderer = am5xy.AxisRendererY.new(root, {
                    // opposite: opposite,
                });
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        maxDeviation: 1,
                        renderer: yRenderer,
                    })
                );

                function createAxisAndSeries(val) {

                    yAxis.get("renderer").grid.template.set("forceHidden", true);
                    if (chart.yAxes.indexOf(yAxis) > 0) {
                        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
                    }

                    // Add series
                    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
                    var series = chart.series.push(
                        am5xy.LineSeries.new(root, {
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: "endingBalance",
                            valueXField: "date",
                            tooltip: am5.Tooltip.new(root, {
                                pointerOrientation: "horizontal",
                                labelText: "{name}: {valueY} (EOD Profit)",
                            }),
                        })
                    );

                    //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
                    series.strokes.template.setAll({
                        strokeWidth: 1
                    });

                    yRenderer.grid.template.set("strokeOpacity", 0.05);
                    yRenderer.labels.template.set("fill", series.get("fill"));
                    yRenderer.setAll({
                        stroke: series.get("fill"),
                        strokeOpacity: 1,
                        opacity: 1,
                    });

                    series.data.processor = am5.DataProcessor.new(root, {
                        dateFormat: "yyyy-MM-dd",
                        dateFields: ["date"],
                    });
                    //console.log('==?val',val)
                    series.data.setAll(val);
                    chart.appear(500, 100);
                }
                xAxis.get("renderer").grid.template.set("forceHidden", true);

                var cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        //xAxis: xAxis,
                        behavior: "zoomX"
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
                if (id == 'LeaderSimulationChartAll') {
                    for (let id in this.finalData) {
                        if (id == 'All') {
                            for (let key in this.finalData[id]) {
                                // console.log("===chart",this.finalData[id][key])
                                createAxisAndSeries(this.finalData[id][key].data);
                            }
                        }
                    }
                } else {
                    createAxisAndSeries(data);
                }

            },
            combineData() {
                if (this.tabtemp.length && this.chartData.length) {
                    let json = {};
                    this.tabtemp.map(item => {
                        json[item.providerId] = {
                            'initialBalance': item.initialBalance,
                            'endingBalance': item.providerId == 'All' ? this.chartData[this.chartData.length - 1].eodBalance : parseFloat(this.chartData[this.chartData.length - 1].providerEod[item.providerId].accumulatedRealizedPnl) + parseFloat(item.initialBalance),
                            'totalProfit': (item.providerId == 'All' ? this.chartData[this.chartData.length - 1].eodBalance - item.initialBalance : this.chartData[this.chartData.length - 1].providerEod[item.providerId].accumulatedRealizedPnl),
                            data: []
                        }
                    })
                    this.finalData['All'] = json
                    this.finalData = Object.assign(this.finalData, json)
                    this.chartData.map(i => {
                        for (let key in i.providerEod) {
                            json[key].data.push({
                                endingBalance: i.providerEod[key].accumulatedRealizedPnl,
                                // dataPointText: i.providerEod[key].accumulatedRealizedPnl,
                                date: moment.unix(i.timestamp).format('YYYY-MM-DD'),
                                balance: i.eodBalance,
                                name: this.tabtemp.filter(i => i.providerId == key)[0].name
                            })
                        }
                    })
                    setTimeout(() => {
                        this.getHeight();
                        this.drawSimulationChart(`LeaderSimulationChartAll`, []);
                        this.tabtemp.map(val => {
                            this.drawSimulationChart(`LeaderSimulationChart${val.providerId}`, this.finalData[val.providerId].data);
                        });
                    }, 1000)
                }
            },
            getUserCopyLeaders() {
                this.store.GetCopyTraders({}, true)
            },
            getHeight(){
                if(this.tradersId?.length > 8){
                    let height = 400 + this.tradersId.length * 20
                    return height
                } else {
                    return 400
                }
            }
        },

        mounted() {
            let _that = this
            $(document).on("click", function(event) {
                if (!$(event.target).closest(".showClass").length) {
                    _that.showAccount = false
                }
            });
            this.connectSimulationSocket();
            // const _that = this;

            // this.socket.onmessage = function (event) {

            // };
        },
        created() {
            if(!Object.keys(this.store.userSelectedAccount).length && this.store.allTypeAccount){
                let data = {};
                if(this.store.allTypeAccount == 'ALL_LIVE'){
                    data = this.store.userTradingAccountsList.find(i => i.demo === false && i.connectivityStatus == 'Connected')
                    if(data !== undefined || data !== null){
                        this.store.$patch({'allTypeAccount' :null})
                        this.store.$patch({'userSelectedAccount' : data})
                    }
                }else if(this.store.allTypeAccount == 'ALL_DEMO'){
                    data = this.store.userTradingAccountsList.find(i => i.demo === true)
                    if(data !== undefined || data !== null){
                        this.store.$patch({'allTypeAccount' :null})
                        this.store.$patch({'userSelectedAccount' : data})
                    }
                }
                this.store.getUserDashboardData({}, true, '', '10000')
                let form = {
                    'targetBrokerAccountId': this.store.userSelectedAccount.brokerAccountId,
                    'page': 0,
                    'size': 10,
                    'sort': 'id,desc',
                };
                this.store.getProviderOpenTrades({}, true);
                this.store.userNotifications(form, false)
            }
            this.store.$patch({
                'compareTraderPerformance': []
            })
            if (this.$route.query.id) {
                this.tradersId = this.$route.query.id.split(",");
                this.getTraderPerformanceData('Start');
            }
            // if(this.store.user.access_token){
            //     this.getUserCopyLeaders();
            // }
        },
    };
</script>
<style>
    /* Chrome, Safari, Edge, Opera */
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    
    input[type=number] {
        -moz-appearance: textfield;
    }
    
    .LeaderSimulationChartAll>div {
        display: none;
    }
    
    .LeaderSimulationChartAll>div:last-child {
        display: block !important;
    }
</style>